.active_nav::after {
    content: '';
    position: absolute;
    left: 0px;
    bottom: 2px;
    width: 100%;
    border-bottom-width: 20px;
    border-left-width: 12px;
    border-right-width: 12px;
    border-bottom-style: solid;
    border-left-style: solid;
    border-right-style: solid;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: rgba(39, 50, 93, 0.049);
    /* border-image: linear-gradient(180.57deg, rgba(39, 50, 93, 0.1) 0.49%, rgba(217, 217, 217, 0) 99.51%); */
    box-shadow: 0px 3px 0px 0px #27325D;
    /* transform: perspective(200px); */
    height: 0px;
    width: 100%;
    overflow: hidden;
}

@media only screen and (max-width: 992px) {
    .active_nav::after {
        bottom: -6px;
        border-bottom-width: 11px;
        border-left-width: 5px;
        border-right-width: 5px;
    }
}