@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;900&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* common css start */
* {
  margin: 0;
  outline: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
  margin: 0;
}

p {
  font-family: "Montserrat", sans-serif;
  margin: 0;
}

a {
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  display: inline-block;
}

.text-number {
  max-width: 80%; /* Adjust the value as needed */
  margin: 0 auto; /* Center the element horizontally */
}

.title_one {
  font-weight: 800;
  font-size: 80px;
  line-height: 94px;
  text-transform: uppercase;
}

.title_two {
  font-weight: 800;
  font-size: 48px;
  line-height: 56px;
  text-transform: uppercase;
}

.para_one {
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;
  color: #212529;
}

.para_two {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
}

.text-light-blue {
  color: #0054b6 !important;
}

.text-dark-blue {
  color: #27325d !important;
}

.text-dark-grey {
  color: #8f8f8f !important;
}

.con-75 {
  width: 75%;
  margin: 0 auto;
}

.hr_line {
  border-bottom: 1px solid rgba(143, 143, 143, 0.5);
}

.input_field {
  font-family: "Raleway";
  background: transparent;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  padding: 15px 25px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
}

.input_field::placeholder {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.5);
}

.input_right_icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  background-color: transparent;
  border: 0px;
}

.close_line {
  width: 20px;
  height: 2px;
  background: #000000;
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(28deg);
}

.close_line1 {
  width: 25px;
  height: 2px;
  background: #969696;
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(28deg);
}

.input_field_c {
  font-family: "Raleway";
  background: transparent;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  padding: 15px 25px;
  padding-left: 60px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
}

.input_icon_left {
  position: absolute;
  left: 21px;
  top: 50%;
  transform: translateY(-50%);
}

.input_field_c::placeholder {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #d9d9d9;
}

.cursor-pointer {
  cursor: pointer;
}

.btn_one {
  font-weight: 500;
  font-size: 20px;
  color: #fff;
  background: #0054b6;
  border-radius: 30px;
  border: 0;
  padding: 8px 25px;
  border: 1px solid #0054b6;
}

.btn_two {
  font-weight: 500;
  font-size: 20px;
  color: #0054b6;
  background: #fff;
  border-radius: 30px;
  border: 0;
  padding: 8px 25px;
  border: 1px solid #0054b6;
  transition: all 0.3s;
  position: relative;
}

.btn_two:hover {
  color: #fff;
  background: #0054b6;
}

.dbl_btn {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(24px);
  border-radius: 80px;
  bottom: 20px;
  display: inline-flex;
}

.dbl_btn button {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  background: transparent;
  border: 0px;
  display: flex;
  padding: 13px 25px;
  gap: 10px;
}

.admin_div {
  background: #ffffff;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  padding: 40px;
}

.c_border {
  border: 1.5px dashed #d9d9d9;
}

/* common css end */

/* icon css start */
.tickSqure {
  width: 24px;
  height: 24px;
}

/* icon css end */

/* header component area start */
.logo-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 120px;
  height: 120px;
  object-fit: contain;
}

.menu {
  border-bottom: 1px solid rgba(143, 143, 143, 0.5);
}

.menu ul {
  text-align: center;
  margin-bottom: 0px;
}

.menu li {
  display: inline-block;
  margin-right: 50px;
}

.menu li:last-child {
  margin-right: 0px;
}

.menu a {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #27325d;
  position: relative;
  padding-bottom: 20px;
}

.menu a::after {
  content: "";
  border-left-color: transparent;
  border-right-color: transparent;
  transition: all 0.3s;
  overflow: hidden;
}

.menu a:hover::after {
  content: "";
  position: absolute;
  left: 0px;
  bottom: 2px;
  width: 100%;
  border-bottom-width: 20px;
  border-left-width: 12px;
  border-right-width: 12px;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: rgba(39, 50, 93, 0.049);
  /* border-image: linear-gradient(180.57deg, rgba(39, 50, 93, 0.1) 0.49%, rgba(217, 217, 217, 0) 99.51%); */
  box-shadow: 0px 3px 0px 0px #27325d;
  /* transform: perspective(200px); */
  height: 0px;
  width: 100%;
}

/* header component area end */

/* footer component area start */
.footer_main {
  display: grid;
  grid-template-columns: 8fr 4fr;
  grid-gap: 30px;
  margin-bottom: 40px;
}

.fm_child1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-logo {
  height: 150px;
  width: 150px;
  object-fit: contain;
}

.copy-right-text {
  margin-top: 20px;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
}

.fm_child2 {
  display: flex;
  justify-content: flex-end;
}

.fm_child2 div {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 70%;
  justify-content: center;
}

.footer-menu {
  width: fit-content;
}

.footer-menu a {
  color: #8f8f8f;
  font-weight: 400;
}

.footer-menu a:hover {
  color: #27325d;
}

/* footer component area end */

/* banner component area start */
.bannerImg {
  background: url(../images/bg-aboutus.png);
  border-radius: 20px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: grid;
  place-items: center;
}

/* banner component area end */

/* home page area start */
.home_section_overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.home_section1 h5 {
  font-weight: 700;
  font-size: 26px;
  line-height: 31px;
  width: max-content;
}

.home_section1 p {
  font-weight: 700;
  font-size: 80px;
  line-height: 98px;
}

.home_slider_arrow {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.home_slider_next_arrow {
  right: 0px;
}

.home_slider_prev_arrow {
  left: 0px;
}

.home-slider {
  width: 70%;
}

.home-slider .sliderImg {
  width: 100%;
  height: auto;
}

.home_sectionThree {
  background: url(../images/bg4.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  clip-path: polygon(0 0, 100% 0%, 100% 83%, 0% 100%);
}

.home_s3 {
  display: grid;
  grid-template-columns: 4fr 8fr;
  min-height: 523px;
}

.home_sec3_child2 {
  height: 100%;
  display: flex;
  align-items: center;
}

.home_sec3_child2 h1 {
  font-weight: 700;
  font-size: 60px;
  line-height: 70px;
  text-transform: capitalize;
}

.home_sec3_child2 img {
  width: 80px;
}

.manImg {
  position: absolute;
  bottom: -7px;
  left: 0;
  height: 519px;
}

/* home page area end */

/* about page area start */
.projectImg {
  width: 100%;
  min-height: 500px;
  object-fit: cover;
}

.projectSlider .alice-carousel__stage-item {
  transform: skew(-20deg);
  margin: 0px 10px;
}

.p_sliderBtn {
  cursor: pointer;
}

/* about page area end */

/* Dev Blogs page area start */
.devBlogs_main {
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-gap: 20px;
  margin-bottom: 80px;
}

.devBlog_card {
  margin-bottom: 40px;
}

.devBlog_card_a:nth-of-type(2) {
  opacity: 0.7;
}

.devBlog_card_a:nth-of-type(3) {
  opacity: 0.4;
}

.devBlog_card .title {
  font-weight: 800;
  font-size: 48px;
  line-height: 56px;
  text-transform: capitalize;
}

.devBlog_card .desc {
  font-weight: 500;
  font-size: 20px;
  line-height: 35px;
  color: #8f8f8f;
}

.devBlog_card button {
  font-weight: 500;
  font-size: 20px;
  color: #fff;
  background: #0054b6;
  border-radius: 30px;
  border: 0;
  padding: 8px 25px;
}

.devBlog_card .date {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000;
  display: flex;
  gap: 10px;
  margin-top: 30px;
}

.all_post_text {
  position: relative;
  display: inline-block;
}

.all_post_text::after {
  content: "";
  position: absolute;
  left: 0px;
  bottom: -5px;
  width: 100%;
  height: 2px;
  background-color: #27325d;
}

.devblogs_ul {
  color: #8f8f8f;
}

.devblogs_ul li {
  margin-top: 20px;
  cursor: pointer;
}

.devblogs_ul li:hover {
  color: #0054b6;
}

.devBlog_image {
  width: 452px;
  height: 334px;
  object-fit: cover;
  border-radius: 30px;
}

/* Dev Blogs page area end */

/* login page area start */
.login_main {
  background: url(../images/Login_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.login_div {
  min-height: 400px;
  width: 400px;
  box-sizing: border-box;
  margin-right: 10vw;
}

.login_div button {
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
}

.Login_btn {
  padding: 10px;
  width: 100%;
  border: 0px;
  background: #0054b6;
  box-shadow: 0px 10px 50px rgba(0, 84, 182, 0.8);
  border-radius: 20px;
  font-weight: 500;
  font-size: 24px;
}

/* login page area end */

/* dev blog details area start */
.devBlogDetails .title {
  font-weight: 800;
  font-size: 48px;
  line-height: initial;
  text-transform: capitalize;
}

.devBlogDetails .desc {
  font-weight: 500;
  font-size: 20px;
  line-height: 35px;
  color: #8f8f8f;
}

.devBlogDetails .date {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000;
  display: flex;
  gap: 10px;
}

/* dev blog details area end */

/* ---------------------------------------------------------- */
/* ---------------------------------------------------------- */
.admin_layout_bg {
  background: url(../images/admin-background.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
}

.admin_container {
  width: 100%;
  margin: 0 auto;
  padding: 40px;
}

.admin_layout {
  display: grid;
  grid-template-columns: 310px 1fr;
  min-height: 500px;
  grid-gap: 30px;
}

.admin_layout_child1 {
  background: #ffffff;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  height: fit-content;
}

.admin_logo {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-bottom: 30px;
  padding-left: 15px;
  margin-top: 40px;
}

.admin_Sidebar li {
  margin-bottom: 15px;
}

.admin_Sidebar a {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 23px 0px;
  border-radius: 15px 15px 0px 15px;
  position: relative;
  left: 15px;
  padding-left: 20px;
}

.admin_Sidebar a:hover {
  background: #0054b6;
  color: #fff !important;
}

.admin_Sidebar a:hover::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -16px;
  border-top: 16px solid transparent;
  border-bottom: 0px solid #002959;
  border-left: 0px solid transparent;
  border-right: 15px solid #002959;
  transform: rotate(179deg);
}

.admin_Sidebar a:hover path {
  stroke: #fff;
  fill: transparent;
}

.admin_menu_icon {
  width: 24px;
  height: 24px;
}

.admin_layout_inner_child {
  margin-top: 30px;
}

.admin_header {
  background: url(../images/admin_header_bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  border-radius: 20px;
  background-blend-mode: color;
  position: relative;
}

.admin_header::after {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 84, 182, 0.8);
  border-radius: 20px;
}

.admin_header_inner {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
}

.admin_header_inner h1 {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;
  color: #ffffff;
}

.admin_header_inner p {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: rgba(255, 255, 255, 0.8);
}

.admin_header_inner h4 {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: right;
  color: #ffffff;
}

.adminImg {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.admin_overlay {
  display: none;
}

.admin_mobile_arrow {
  display: none;
}

.m_arrow {
  width: 48px;
  height: 48px;
}

/* dashboard area start */
.dash_div1 {
  display: grid;
  grid-template-columns: 180px 1fr;
  grid-gap: 20px;
}

.input_file_area {
  width: 100%;
  height: 400px;
  border: 1.5px dashed #d9d9d9;
  border-radius: 30px;
  position: relative;
  /* background-image: repeating-linear-gradient(185deg, #d9d9d9, #d9d9d9 14px, transparent 14px, transparent 25px, #d9d9d9 25px), repeating-linear-gradient(275deg, #d9d9d9, #d9d9d9 14px, transparent 14px, transparent 25px, #d9d9d9 25px), repeating-linear-gradient(5deg, #d9d9d9, #d9d9d9 14px, transparent 14px, transparent 25px, #d9d9d9 25px), repeating-linear-gradient(95deg, #d9d9d9, #d9d9d9 14px, transparent 14px, transparent 25px, #d9d9d9 25px);
    background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat; */
  text-align: center;
  object-fit: cover;
}

.input_file_area input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.input_image_main {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  z-index: 1;
}

.input_desc {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 33px;
  text-align: center;
  color: #8f8f8f;
  width: max-content;
  margin: 0 auto;
}

.input_file_img {
  display: block;
  margin: 0 auto;
}

.input_file_im_text_main {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* text-align: center; */
  color: #8f8f8f;
  width: max-content;
}

.input_file_im_text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #8f8f8f;
  width: max-content;
}

.dash_slider_arrow {
  cursor: pointer;
}

.arrow_right {
  transform: rotate(180deg);
}

.dash_slider_title {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #000000;
}

.dash_div1_child2 {
  overflow: hidden;
}

.dashImg {
  width: 100%;
  height: 300px;
  box-shadow: 0px 0px 13px -4px grey;
  border-radius: 30px;
  object-fit: cover;
  /* margin: 0px; */
}

.dashSlider a {
  padding: 10px;
  width: 100%;
}

.das_slider_btn {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(24px);
  border-radius: 80px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  display: flex;
}

.das_slider_btn button {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  background: transparent;
  border: 0px;
  display: flex;
  padding: 5px 25px;
  gap: 10px;
}

.dash_div2 {
  margin-top: 30px;
}

.dash_div2_header {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 20px;
}

.blog_card_img {
  width: 268px;
  height: 199px;
  object-fit: cover;
  border-radius: 30px;
}

.blog_card {
  margin-top: 20px;
  display: flex;
  gap: 20px;
}

/* dashboard area end */

/* blog area start */
.blog_div1 {
  font-size: 15px;
  line-height: 20px;
  border-radius: 30px;
  display: grid;
  grid-template-columns: 260px 1fr;
  grid-gap: 20px;
}

.blog_div1 .child .input_file_area {
  height: 260px !important;
  border-radius: 30px;
}

.blog_div1 .child3 {
  grid-column: 1/3;
}

/* blog area end */

/* Account Settings start */
.userIcon_area {
  position: relative;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
}

.userIcon {
  border-radius: 50%;
}

.user_input_file {
  left: 0;
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  opacity: 0;
  cursor: pointer;
}

/* Account Settings end */

.horizontal_line {
  border-bottom: 1px solid #d9d9d9;
  margin: 80px 0px;
}

.changepass_ul li {
  /* padding-left: 20px; */
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #8f8f8f;
  margin-top: 30px;
}

.cp_matching_item {
  text-decoration-line: line-through;
  color: #0054b6 !important;
}

.cp_matching_item .tickSqure path {
  stroke: #0054b6 !important;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

#notfound {
  position: relative;
  height: 100vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 410px;
  width: 100%;
  text-align: center;
}

.notfound .notfound-404 {
  height: 280px;
  position: relative;
  z-index: -1;
}

.notfound .notfound-404 h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 230px;
  margin: 0px;
  font-weight: 900;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  background: url("../images/bg.jpg") no-repeat;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
}

.notfound h2 {
  font-family: "Montserrat", sans-serif;
  color: #000;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
}

.notfound p {
  font-family: "Montserrat", sans-serif;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 0px;
}

.notfound button {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: #0046d5;
  display: inline-block;
  padding: 15px 30px;
  border-radius: 40px;
  color: #fff;
  font-weight: 700;
  -webkit-box-shadow: 0px 4px 15px -5px #0046d5;
  box-shadow: 0px 4px 15px -5px #0046d5;
}

@media only screen and (max-width: 767px) {
  .notfound .notfound-404 {
    height: 142px;
  }
  .notfound .notfound-404 h1 {
    font-size: 112px;
  }
}

/* ---------------------------------------------------------- */
/* ---------------------------------------------------------- */
