/* responsive start */
@media only screen and (min-width: 1400px) {
    .fm_child1 {
        justify-content: space-around;
    }

    .admin_container{
        width: 1399px;
    }
}

@media only screen and (max-width: 1199px) {
    .footer-logo {
        height: 100px;
        width: 100px;
    }

    .footer-menu a {
        font-size: 18px;
    }

    .copy-right-text {
        font-size: 18px;
    }

    .fm_child2 div {
        width: 76%;
    }

    .fm_child2 img {
        width: 50px;
    }

    .home_section1 h5 {
        font-size: 19px;
    }

    .home_section1 p {
        font-size: 60px;
        line-height: 71px;
    }

    .title_one {
        font-size: 60px;
        line-height: 71px;
    }

    .manImg {
        height: 390px;
    }

    .home_sec3_child2 h1 {
        font-size: 45px;
        line-height: 50px;
    }

    .home_sec3_child2 img {
        width: 60px;
    }

    .home_s3 {
        min-height: 417px;
    }

    .devBlog_image {
        width: 348px;
        height: 240px;
    }

    .devBlog_card .title {
        font-size: 30px;
        line-height: 30px;
    }

    .devBlog_card .desc {
        font-size: 14px;
        line-height: 24px;
    }

    .devBlog_card .date {
        font-size: 15px;
        margin-top: 20px;
    }

    .devBlog_card .date img {
        width: 20px;
    }

    .devBlog_card button {
        font-size: 14px;
        line-height: initial;
        padding: 5px 21px;
    }
}

@media only screen and (max-width: 1200px) {
    .admin_menu_icon {
        width: 20px;
        height: 20px;
    }

    .admin_Sidebar a {
        font-size: 16px;
    }

    .admin_layout {
        grid-template-columns: 265px 1fr;
        grid-gap: 30px;
    }
}

@media only screen and (max-width: 991px) {

    .devBlogs_main {
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width: 992px) {
    .logo {
        width: 80px;
        height: 80px;
    }

    .menu ul {
        margin-bottom: 8px;
    }

    .menu a:hover::after {
        bottom: -6px;
        border-bottom-width: 11px;
        border-left-width: 5px;
        border-right-width: 5px;
    }

    .menu li {
        margin-right: 20px;
    }

    .menu a {
        font-size: 18px;
        padding-bottom: 2px;
    }

    .footer-logo {
        display: none;
    }

    .fm_child2 div {
        width: 100%;
    }

    .home_section1 p {
        font-size: 40px;
        line-height: 45px;
    }

    .home_section1 h5 {
        font-size: 13px;
        line-height: 19px;
    }

    .title_one {
        font-size: 40px;
        line-height: 45px;
    }

    .home_sec3_child2 h1 {
        font-size: 30px;
        line-height: 37px;
    }

    .home_s3 {
        min-height: 284px;
    }

    .manImg {
        height: 269px;
    }

    .admin_layout {
        grid-template-columns: 1fr;
    }

    .admin_layout_child1 {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 999;
        border-radius: 0px;
        transition: all 0.3s;
    }

    .admin_overlay {
        display: block;
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: #0055b6a4;
        z-index: 991;
        transition: all 0.3s;
    }

    .close_icon {
        font-size: 30px;
        position: absolute;
        right: 20px;
        top: 20px;
        font-weight: 500;
        color: #fff;
    }

    .admin_mobile_arrow {
        display: block;
        width: 50px;
        height: 50px;
        background-color: #0054B6;
        border-radius: 0px 20px 20px 0px;
        position: fixed;
        z-index: 100;
    }

}

@media only screen and (max-width: 767px) {
    .home_section1 img {
        width: 220px !important;
    }

    .home_slider_arrow {
        width: 50px;
    }

    .home_s3 {
        grid-template-columns: 1fr;
    }

    .home_sec3_child1 {
        order: 2;
    }

    .home_sec3_child2 {
        order: 1;
        justify-content: center;
    }

    .home_s3 {
        min-height: 491px;
    }

    .manImg {
        height: 269px;
        left: 50%;
        transform: translateX(-50%);
    }

    .home_sec3_child2 img {
        width: 40px;
    }

    .home_sectionThree {
        clip-path: polygon(0 0, 100% 0%, 100% 90%, 0% 100%);
    }

    .admin_header_inner {
        flex-direction: column;
        gap: 20px;
        padding: 15px;
    }

    .admin_container {
        padding: 40px 10px;
    }

    .admin_logo {
        width: 70px;
        height: 70px;
        margin-bottom: 7px;
        margin-top: 8px;
    }

    .admin_Sidebar a {
        padding: 15px 0px;
        padding-left: 15px;
    }

    .admin_Sidebar li {
        margin-bottom: 10px;
    }

    .input_file_area {
        height: 200px;
    }

    .input_desc {
        font-size: 15px;
        line-height: 20px;
    }

    .input_file_img {
        width: 40px;
    }
}

@media only screen and (max-width: 768px) {
    .title_one {
        font-size: 30px;
        line-height: 45px;
    }

    .logo {
        width: 50px;
        height: 50px;
        display: inline;
    }

    .menu li {
        margin-right: 20px;
    }

    .menu a {
        font-size: 18px;
    }

    .humberger {
        display: block;
    }

    .footer_main {
        grid-template-columns: 1fr;
        gap: 10px;
    }

    .fm_child1 {
        justify-content: center;
        order: 2;
    }

    .fm_child2 {
        order: 1;
        justify-content: center;
    }

    .fm_child2 img {
        width: 25px;
    }

    .copy-right-text {
        margin-top: 8px;
        text-align: center;
    }

    .p_sliderBtn {
        width: 50px;
    }

    .devBlogs_main {
        margin-bottom: 20px;
    }

    .projectImg {
        min-height: 398px;
    }

    .login_main {
        justify-content: center;
    }

    .login_div {
        margin-right: 0vw;
        margin: 0px 20px;
    }

    .login-logo {
        width: 80px;
    }

    .dash_div1 {
        grid-template-columns: 1fr;
    }

    .para_two {
        font-size: 16px;
    }

    .blog_card {
        flex-direction: column;
    }

    .btn_one {
        font-size: 16px;
        border-radius: 20px;
    }

    .dash_slider_title {
        font-size: 22px;
    }

    .blog_div1 .child1 {
        grid-column: 1/3;
    }

    .blog_div1 .child2 {
        grid-column: 1/3;
    }

    .horizontal_line {
        border-bottom: 1px solid #D9D9D9;
        margin: 40px 0px;
    }
}

@media only screen and (max-width: 600px) {
    .menu a {
        font-size: 12px;
    }

    .copy-right-text {
        font-size: 12px;
    }

    .home-slider {
        width: 100%;
    }

    .home_slider_arrow {
        width: 30px;
    }

    .con-75 {
        width: 100%;
    }

    .para_one {
        font-size: 18px;
        line-height: 29px;
        text-align: justify !important;
    }

    .devblogs_ul li {
        margin-top: 8px;
    }

    .all_post_text {
        font-size: 18px;
    }

    .all_post_text::after {
        bottom: -2px;
    }

    .projectImg {
        min-height: 300px;
    }

    .title_two {
        font-size: 30px;
        line-height: 23px;
    }


    .input_field {
        font-size: 16px;
        line-height: 20px;
        padding: 12px 25px;
        border-radius: 10px;
    }

    .input_field::placeholder {
        font-size: 16px;
        line-height: 20px;
    }

    .input_field_c{
        font-size: 16px;
        line-height: 20px;
        padding: 12px 25px;
        padding-left: 60px;
        border-radius: 10px;
    }

    .input_field_c::placeholder {
        font-size: 16px;
        line-height: 20px;
    }

    .input_right_icon {
        right: 10px;
    }

    .menu a {
        padding-bottom: 0px;
    }

    .devBlogDetails .title {
        font-size: 30px;
        line-height: 30px;
    }

    .devBlogDetails .date {
        font-size: 15px;
        margin-top: 20px;
    }

    .devBlogDetails .date img {
        width: 20px;
    }

    .devBlogDetails .desc {
        font-size: 14px;
        line-height: 24px;
    }

    .devBlogDetails button {
        font-size: 14px;
        line-height: initial;
        padding: 5px 21px;
    }

    .dash_div1 {
        padding: 15px;
    }

    .admin_div {
        padding: 20px;
    }

    .blog_div1{
        grid-gap: 20px 20px;
        grid-template-columns: 1fr;
    }

    .btn_w_100{
        width: 100%;
    }

    .changepass_ul li{
        font-size: 15px;
    }

    .horizontal_line {
        border-bottom: 1px solid #D9D9D9;
        margin: 30px 0px;
    }

}

@media only screen and (max-width: 425px) {
    .home_sectionThree {
        clip-path: polygon(0 0, 100% 0%, 100% 93%, 0% 100%);
    }

    .home_sec3_child2 h1 {
        font-size: 22px;
        line-height: 35px;
    }

    .devBlog_image {
        width: 100%;
    }


    .dashImg {
        height: 200px;
        margin-top: 0px !important;
    }

    .das_slider_btn button {
        font-size: 15px;
        gap: 5px;
    }

    .das_slider_btn img {
        width: 20px;
    }

    .blog_card img {
        width: 100%;
    }
}

/* responsive end */